import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Recipes" />
    <h1>Dayle's Recipes</h1>
    <h2>Neopolitan Pizza</h2>
    <p>https://www.joshuaweissman.com/post/homemade-authentic-pizza</p>
    <p>https://www.joshuaweissman.com/post/new-york-pizza-vs-italian-pizza</p>
    <h3>Ingredients</h3>
    <p>- 512g water (95 degrees F)</p>
    <p>- 3-4g yeast</p>
    <p>- 800g 'OO' flour </p>
    <p>- 16g salt</p>
    <h3>Instructions</h3>
    <p>1. Mix until a shaggy dough is formed and is hydrated</p>
    <p>2. Cover and let rest 20 min</p>
    <p>3. Slap and fold dough for 1 to 2 min or until smooth</p>
    <p>4. Cover and let rest 15 min</p>
    <p>5. Slap and fold dough for 1 to 2 min or until supple and doesn't stick</p>
    <p>6. Cover dough and refrigerate 14 to 18 hours (make the dough at 8p and go to next step at noon next day)</p>
    <p>7. Divide dough into 6 equal pieces, about 215g each</p>
    <p>8. Proof dough 3-4 hours between room temperature and 100 degrees until lightly puffed and slightly risen</p>
    <p>9. Preheat oven to maximum convection oven temperature (500 or 550 degrees F) for 1 hour</p>
    <p>10. Begin baking </p>
    <h2>Mango Ice Cream</h2>
    <p>https://culinaryshades.com/mango-ice-cream-recipe/</p>
    <h3>Ingredients</h3>
    <p>- 1.5 cups mango pulp</p>
    <p>- 1 cup heavy cream</p>
    <p>- 0.5 cup milk (or evaporated milk)</p>
    <p>- 0.5 cup sugar</p>
    <p>- 1 pinch salt</p>
    <h4>Instructions</h4>
    <p>1. Mix mango pulp milk, sugar, and salt and set aside in the refrigerator</p>
    <p>2. Whip the cream, gently fold in the mango mixture, and leave in the fridge overnight if possible</p>
    <p>3. Churn for 20 minutes, then freeze in freezer</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
      <Link to="/page-2/">Itinerary</Link>
  </Layout>
)

// https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
export default IndexPage
